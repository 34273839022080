import { useState } from 'react';
import { useRecognitionsData } from '../../hooks/Recognitions/useRecognitionsData';
import { Result } from '../../types/recognition';
import RecognitionsViewer from './components/RecognitionsViewer';
import { ResultsViewerDialog } from './components/ResultsViewerDialog';
import { RecognitionsTable } from './components/RecognitionsTable';

export const Recognitions = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [resultsObject, setResultsObject] = useState<Result[] | null>(null);

  const {
    recognitions,
    handleSort,
    paginationParameters,
    paginatorState,
    setPaginatorState,
    internalFilters,
    changeFilter,
    clearFilters,
  } = useRecognitionsData();

  return (
    <>
      <ResultsViewerDialog resultsObject={resultsObject} setResultsObject={setResultsObject} />
      <RecognitionsViewer activeIndex={activeIndex} setActiveIndex={setActiveIndex} recognitions={recognitions} />
      <RecognitionsTable
        setResultsObject={setResultsObject}
        setActiveIndex={setActiveIndex}
        recognitions={recognitions}
        clearFilters={clearFilters}
        internalFilters={internalFilters}
        changeFilter={changeFilter}
        paginationParameters={paginationParameters}
        handleSort={handleSort}
        paginatorState={paginatorState}
        setPaginatorState={setPaginatorState}
      />
    </>
  );
};
