import { useState, useCallback, useEffect } from 'react';
import { Station } from '../../types/station';
import stationsService from '../../services/ScaleoAdminApiServices/StationsService';
import recognitionsService from '../../services/ScaleoAdminApiServices/RecognitionsService';
import { Recognition } from '../../types/recognition';

export const useRecognitionsData = () => {
  const [componentData, setComponentData] = useState<{
    recognitions: Recognition[];
    stations: Station[];
  }>({
    recognitions: [],
    stations: [],
  });
  const [filters, setFilters] = useState<any>({});
  const [internalFilters, setInternalFilters] = useState(filters);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);

  const fetchRecognitions = useCallback(
    async (_filters: any, _paginationParameters: any) => {
      void Promise.all([
        recognitionsService.searchRecognitions(_filters, _paginationParameters),
        stationsService.getStations(),
      ]).then((response) => {
        setComponentData({
          recognitions: response[0].data.data,
          stations: response[1].data,
        });

        setPaginatorState({
          ...paginatorState,
          totalPages: response[0].data.totalPagesNumber,
          currentPage: paginatorState.currentPage > response[0].data.totalPagesNumber ? 1 : paginatorState.currentPage,
        });
      });
    },
    [
      recognitionsService.searchRecognitions,
      stationsService.getStations,
      setComponentData,
      setPaginatorState,
      paginatorState,
    ],
  );

  useEffect(() => {
    void fetchRecognitions(internalFilters, paginationParameters);
  }, [internalFilters, paginationParameters]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const changeFilter = (value: any, name: string) => {
    const newFilters: any = { ...internalFilters };
    newFilters[name] = value;

    setFilters(newFilters);
    setInternalFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters({});
    setInternalFilters({});
  };

  return {
    recognitions: componentData.recognitions,
    stations: componentData.stations,
    handleSort,
    paginationParameters,
    paginatorState,
    setPaginatorState,
    internalFilters,
    changeFilter,
    clearFilters,
    filters,
  };
};

const emptyPaginationParameters = {
  order: {
    isAscending: true,
    orderColumn: 'key',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const emptyPaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};
