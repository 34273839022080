export enum LicenseType {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum LicenseVersion {
  '0.0.1' = '0.0.1',
  '2.1.42' = '2.1.42',
  '2.1.43' = '2.1.43',
  '2.1.44' = '2.1.44',
  '2.1.45' = '2.1.45',
  '2.1.46' = '2.1.46',
  '2.1.47' = '2.1.47',
  '2.1.48' = '2.1.48',
  '2.1.49' = '2.1.49',
  '2.1.50' = '2.1.50',
  '2.1.51' = '2.1.51',
  '2.1.52' = '2.1.52',
  '2.1.53' = '2.1.53',
  '2.2.0' = '2.2.0',
  '2.2.1' = '2.2.1',
  '2.2.2' = '2.2.2',
  '2.2.3' = '2.2.3',
  '2.2.22' = '2.2.22',
  '2.2.35' = '2.2.35',
  '4.0.17' = '4.0.17',
  '4.2.26' = '4.2.26',
  '4.2.31' = '4.2.31',
  '4.2.46' = '4.2.46',
  '4.2.47' = '4.2.47',
  '4.3.1' = '4.3.1',
  '4.3.2' = '4.3.2',
  '4.4.1' = '4.4.1',
  '4.4.2' = '4.4.2',
  '4.4.3' = '4.4.3',
  '4.4.4' = '4.4.4',
  '4.4.5' = '4.4.5',
  '4.4.6' = '4.4.6',
  '4.4.7' = '4.4.7',
  '4.4.8' = '4.4.8',
  '4.4.9' = '4.4.9',
  '4.4.10' = '4.4.10',
  '4.4.11' = '4.4.11',
  '4.4.12' = '4.4.12',
  '4.5.1' = '4.5.1',
  '4.6.1' = '4.6.1',
  '4.6.2' = '4.6.2',
  '4.6.3' = '4.6.3',
  '4.6.4' = '4.6.4',
  '4.6.5' = '4.6.5',
  '4.7.1' = '4.7.1',
  '4.7.2' = '4.7.2',
  '4.7.3' = '4.7.3',
  '4.7.4' = '4.7.4',
  '4.7.5' = '4.7.5',
  '4.8.1' = '4.8.1',
  '4.8.2' = '4.8.2',
  '4.8.3' = '4.8.3',
  '4.9.1' = '4.9.1',
  '4.10.1' = '4.10.1',
  '4.10.2' = '4.10.2',
  '4.10.3' = '4.10.3',
  '4.10.4' = '4.10.4',
  '4.12.1' = '4.12.1',
  '4.12.2' = '4.12.2',
  '4.12.3' = '4.12.3',
  '4.12.4' = '4.12.4',
  '4.12.5' = '4.12.5',
  '4.12.6' = '4.12.6',
  '4.12.7' = '4.12.7',
  '4.12.8' = '4.12.8',
  '4.12.9' = '4.12.9',
  '4.12.10' = '4.12.10',
  '4.12.11' = '4.12.11',
  '4.12.12' = '4.12.12',
  '4.12.13' = '4.12.13',
  '4.12.14' = '4.12.14',
  '4.12.15' = '4.12.15',
  '4.12.16' = '4.12.16',
  '4.12.17' = '4.12.17',
  '4.12.18' = '4.12.18',
  '4.12.19' = '4.12.19',
  '4.12.20' = '4.12.20',
  '4.12.21' = '4.12.21',
  '4.13.1' = '4.13.1',
  '4.13.2' = '4.13.2',
  '4.13.3' = '4.13.3',
  '4.13.4' = '4.13.4',
  '4.13.5' = '4.13.5',
  '4.13.6' = '4.13.6',
  '4.13.7' = '4.13.7',
  '4.13.8' = '4.13.8',
  '4.13.9' = '4.13.9',
  '4.13.10' = '4.13.10',
  '4.13.11' = '4.13.11',
  '4.13.12' = '4.13.12',
  '4.13.13' = '4.13.13',
  '4.13.14' = '4.13.14',
  '4.13.15' = '4.13.15',
  '4.13.16' = '4.13.16',
  '4.13.17' = '4.13.17',
  '4.13.18' = '4.13.18',
  '4.13.19' = '4.13.19',
  '4.13.20' = '4.13.20',
  '4.13.21' = '4.13.21',
  '4.13.22' = '4.13.22',
}

export interface Limits {
  usersNumber?: number;
  maximumUsersNumber?: number;
  scalesNumber?: number;
  locationsNumber?: number;
  maximumMeasurementsNumber?: number;
  additionalFieldsNumber?: number;
  allowGenerateReports: boolean;
  addWatermark: boolean;
  allowApiAccess: boolean;
  useImagePosReceipts: boolean;
  allowEdgeDeviceConfigurationUpdate: boolean;
  usePolishBDOLanguage: boolean;
  turnOnHttpNodeAuth: boolean;
  turnOnAutomaticZeroing: boolean;
}

export interface License {
  id: string;
  customerId: string;
  email: string;
  deviceKey?: string;
  licenseKey?: string;
  version: LicenseVersion;
  type: LicenseType;
  expirationDate?: Date;
  subscriptionModules: string[];
  companyName: string;
  limits?: Limits;
  remarks?: string;
}
