import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { CustomTableField } from '../../components/_shared/CustomComponents';
import { Recognition, Result } from '../../types/recognition';
import { adaptJsonToStrings } from '../../utils/adapt-json-to-strings';

export const useColumnsTemplates = (
  recognitions: Recognition[],
  setResultsObject: (results: Result[] | null) => void,
  setActiveIndex: (n: number | null) => void,
) => {
  const columnBodyTemplate = (rowData: Recognition, column: any) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const registrationNumberScoresTemplate = (rowData: Recognition) =>
    rowData.registrationScores.map((r) => (
      <>
        <Tag severity="success" value={r.registrationNumber} style={{ margin: '0.1vh' }} /> <b>{r.score * 100} %</b>
      </>
    ));

  const resultsBodyTemplate = (rowData: Recognition) => (
    <>
      <Button onClick={() => setResultsObject(adaptJsonToStrings(rowData.results))}>Pokaż</Button>
    </>
  );

  const imageTemplate = (rowData: Recognition) => {
    const index = recognitions.findIndex((r) => r.url === rowData.url && r.key === rowData.key);
    return (
      <img
        src={rowData.url}
        alt="loading"
        width="80px"
        onClick={() => setActiveIndex(index)}
        className="cursor-pointer"
        style={{ cursor: 'pointer' }}
      />
    );
  };

  return {
    columnBodyTemplate,
    registrationNumberScoresTemplate,
    resultsBodyTemplate,
    imageTemplate,
  };
};
